import decode from 'jwt-decode';

import SocketResource from '@/resources/socket';

import {
  createUser,
  getUser,
  requestEmailCode,
  updateUser,
  validateEmailCode,
  validatePasswordResetHash,
} from '@/resources/users/index';
import { CREDENTIALS, invalidateSession } from '@/utils/session';
import { login, pin } from '@/resources/users/authentication';
import { funcoes as functions } from '@/utils/enumerados';

import router from '@/router';

import { types as auctionsTypes } from '../ofertasCompra';
import { types as auctionItemsTypes } from '../ofertasCompraItens';
import { types as bidsTypes } from '../lances';
import { types as dispensationsTypes } from '../dispensas';
import { types as pendingCompaniesTypes } from '../empresasPendentes';

const types = {
  CHOOSE_COMPANY: 'CHOOSE_COMPANY',
  LOGIN: 'LOGIN',
  LOGGING: 'LOGGING',
  PIN: 'PIN',
  RELOAD_INDEX_INCREASE: 'RELOAD_INDEX_INCREASE',
  USER: 'USER',
  USER_COMPANY: 'USER_COMPANY',
  USER_EXIT: 'USER_EXIT',
  USER_VALIDATE_HASH_PASSWORD_RESET: 'USER_VALIDATE_HASH_PASSWORD_RESET',
};

const verifiedEmailStatus = {
  CONFIRMED: 'CONFIRMADO',
  PROFILE: 'PERFIL',
  REGISTER: 'CADASTRO',
};

const getKeepConnected = () => localStorage.getItem(CREDENTIALS.KEEP_CONNECTED);

const mutations = {
  [types.LOGIN](state, token) {
    const keepConnected = getKeepConnected();
    if (token && typeof token === 'string') {
      if (keepConnected) {
        localStorage.setItem(CREDENTIALS.VALUE, token);
      } else {
        sessionStorage.setItem(CREDENTIALS.VALUE, token);
      }
    }
    state.token.id = keepConnected
      ? localStorage.getItem(CREDENTIALS.VALUE)
      : sessionStorage.getItem(CREDENTIALS.VALUE);

    if (state.token.id) {
      const decodedToken = decode(state.token.id);
      state.token.user = decodedToken.id;
      state.token.company = decodedToken.empresa;
      const decodedTokenDate = new Date(0);
      decodedTokenDate.setUTCSeconds(decodedToken.exp);
      state.token.expiration = decodedTokenDate;
      state.company.id = decodedToken.empresa;
      state.logged = true;

      invalidateSession();
    }
  },
  [types.PIN](state, valido) {
    if (typeof valido === 'boolean') {
      state.validPin = valido;
    }
  },
  [types.USER](state, user) {
    if (user && typeof user === 'object') {
      state.user = user;
      if (user.empresas.length === 1) {
        state.company = [user.empresas];
      }
    }
  },
  [types.USER_COMPANY](state, company) {
    if (company && typeof company === 'object') {
      state.company = company;
      state.token.company = company.id;
      state.logged = true;
    }
  },
  [types.USER_EXIT](state) {
    const keepConnected = getKeepConnected();

    if (keepConnected) {
      localStorage.removeItem(CREDENTIALS.VALUE);
    } else {
      sessionStorage.removeItem(CREDENTIALS.VALUE);
    }

    localStorage.removeItem(CREDENTIALS.KEEP_CONNECTED);
    localStorage.setItem(CREDENTIALS.FLUSH, Date.now().toString());
    localStorage.removeItem(CREDENTIALS.FLUSH);

    state.user = {};
    state.company = {};
    state.logged = false;
    state.token.id = 0;
    state.chooseCompany = false;
    state.validPin = false;
  },
  [types.CHOOSE_COMPANY](state, data) {
    if (typeof data === 'boolean') {
      state.chooseCompany = data;
    }
  },
  [types.USER_VALIDATE_HASH_PASSWORD_RESET](state, data) {
    if (data && typeof data === 'object') {
      if (data.type === 'HashValido') state.passwordResetHash = true;
    }
  },
  [types.LOGGING](state, logging) {
    if (typeof logging === 'boolean') {
      state.logging = logging;
    }
  },
  [types.RELOAD_INDEX_INCREASE](state) {
    state.reloadIndex += 1;
  },
};

const actions = {
  async createUser({ commit }, user) {
    const response = await createUser(user);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.USER, response.data.data);
  },
  async fetchUser({ commit }, userId) {
    const response = await getUser(userId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.USER, response.data.data);
  },
  async requestEmailCode({ commit, state }) {
    const response = await requestEmailCode(state.user.id);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.USER, response.data);
  },
  async updateUser({ commit, state }, user) {
    const response = await updateUser(state.user.id, user);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.USER, response.data.data);
  },
  async startSession({ commit, dispatch }) {
    const token = sessionStorage.getItem(CREDENTIALS.VALUE);
    commit(types.LOGIN, token);
    return dispatch('finishLogin');
  },
  async finishLogin({ commit, dispatch, rootState, state }, data) {
    if (state.token.id) {
      await dispatch('fetchUser', state.token.user);
      if (state.token.company) {
        await dispatch('companies/fetchCompany', state.token.company);
        if (data && !data.withoutReload) commit(types.RELOAD_INDEX_INCREASE);
      }
    }

    const { company } = rootState.companies;
    if (company.status === 'BLOQUEADA') {
      router.push({ path: '/comunicado' });
    }

    return commit(types.LOGGING, false);
  },
  async login({ commit, state, dispatch }, data) {
    commit(types.LOGGING, true);

    const loginData = { ...data };
    if (state.chooseCompany) {
      loginData.chooseCompany = state.chooseCompany;
    }
    const response = await login(loginData);
    if (response.status !== 200) return Promise.reject(response);
    const { data: user } = response;

    if (loginData.keepConnected) {
      localStorage.setItem(CREDENTIALS.KEEP_CONNECTED, true);
    }

    commit(types.LOGIN, user.token);

    const socketResource = new SocketResource(this._vm.$socket);
    await socketResource.closeConnection();
    await socketResource.openConnection();

    return dispatch('finishLogin', data);
  },
  logout({ commit, rootState, dispatch }, redirect = true) {
    const { logged } = rootState.users;
    const { company } = rootState.companies;

    if (company.id === 1) {
      commit('prefectures/PREFECTURE_ADMIN_CLEAN_FILTERS');
      commit(pendingCompaniesTypes.EMPRESAS_PENDENTES_ADMIN_LIMPAR_FILTROS);
    } else {
      commit(dispensationsTypes.DISPENSAS_LIMPAR_FILTROS);
      commit(auctionsTypes.OFERTA_COMPRA_LIMPAR_FILTROS);
    }

    if (logged) {
      commit('companies/COMPANY_CLEAN');
      commit('prefectures/PREFECTURE_CLEAN');
      commit('proposals/AUCTION_PROPOSALS_CLEAN');
      dispatch('cleanDispensations');
      dispatch('cleanAuctions');
      commit(auctionItemsTypes.ITENS_LIMPAR);
      commit(bidsTypes.LIMPAR_OFERTA_COMPRA_LANCES);
      commit(types.USER_EXIT);

      if (redirect && rootState.route.path !== '/') {
        router.push({ path: '/' });
      }
    }
    const socketResource = new SocketResource(this._vm.$socket);
    socketResource.closeConnection();
    socketResource.openConnection();
  },
  async validateEmailCode({ commit, state }, data) {
    const response = await validateEmailCode(state.user.id, data);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.USER, response.data);
  },
  async validatePasswordResetHash({ commit }, dados) {
    const response = await validatePasswordResetHash(dados);
    if (response.status === 200 && response.data.type) {
      commit(types.USER_VALIDATE_HASH_PASSWORD_RESET, response.data);
    }
  },
  async validatePin({ commit }, dados) {
    const { data: resposta } = await pin(dados);
    commit(types.PIN, resposta.valido);
  },
  invalidatePin({ commit }) {
    const dados = false;
    commit(types.PIN, dados);
  },
  chooseCompanyUser({ commit }, dados) {
    commit(types.CHOOSE_COMPANY, dados);
  },
  loginCompany({ commit }, company) {
    commit(types.USER_COMPANY, company);
  },
};

const getters = {
  getChooseCompany(state) {
    return state.chooseCompany;
  },
  getConfirmedUserEmail(state) {
    return state.user.emailVerificado === verifiedEmailStatus.CONFIRMED;
  },
  getExpirationToken(state) {
    return state.token.expiration;
  },
  getFunction(state, _, rootState, rootGetters) {
    if (!state.user.id || !rootState.companies.company.id) return functions.VISITANTE;

    const auction = rootState.ofertasCompra.ofertaCompra;
    const { user } = state;
    const { company } = rootState.companies;
    if (auction.id) {
      if (state.company.id === auction.comprador.id) {
        const responsible = rootState.responsaveis.responsaveis;
        const crier = responsible.find(element => element.funcao === functions.PREGOEIRO);
        const authority = responsible.find(element => element.funcao === functions.AUTORIDADE);
        if (crier && state.user.id === crier.usuario_id) {
          return functions.PREGOEIRO;
        }
        if (authority && state.user.id === authority.usuario_id) {
          return functions.AUTORIDADE;
        }

        return functions.COMPRADOR;
      }

      if (state.company.seller) {
        const operateAuctions = user.empresas.some(
          companyUser => companyUser.id === company.id && companyUser.operar_pregoes,
        );
        if (operateAuctions) {
          const proposals = rootGetters['proposals/obterPropostasUsuarioLogado'];
          if (proposals.length > 0) return functions.LICITANTE;
          return functions.FORNECEDOR;
        }
      }
    }

    const dispensation = rootState.dispensas.dispensa;
    if (dispensation.id && state.company.seller) {
      const operateDispensations = user.empresas.some(
        companyUser => companyUser.id === company.id && companyUser.operar_dispensas,
      );
      if (operateDispensations) {
        const bids = rootState.dispensasLances.lances;
        if (bids.length) return functions.LICITANTE;
        return functions.FORNECEDOR;
      }
    }

    if (company.buyer) return functions.COMPRADOR;
    if (company.seller) return functions.VENDEDOR;
    return functions.USUARIO;
  },
  getLogged(state) {
    return state.logged;
  },
  getLogging(state) {
    return state.logging;
  },
  getUser(state) {
    return state.user;
  },
  getRegisteredUser(state) {
    return state.user.emailVerificado === verifiedEmailStatus.PROFILE;
  },
  getReloadIndex(state) {
    return state.reloadIndex;
  },
  getResetPasswordHash(state) {
    return state.passwordResetHash;
  },
  getToken(state) {
    return state.token.id;
  },
  getUserToken(state) {
    return state.token.user;
  },
  getValidPin(state) {
    return state.validPin;
  },
};

const state = {
  chooseCompany: false,
  company: {
    id: 0,
  },
  logged: false,
  logging: false,
  passwordResetHash: false,
  reloadIndex: 0,
  token: {
    company: null,
    expiration: null,
    id: null,
    user: null,
  },
  user: {
    id: 0,
    empresas: [],
  },
  validPin: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
