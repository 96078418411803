<template>
  <div class="panel-block" @keypress.enter="validarFiltros">
    <b-field grouped class="column-direction-touch">
      <b-field label="Situação">
        <b-select expanded placeholder="Situação" v-model="filters.situation">
          <option v-for="situation in situations" :key="situation.id" :value="situation.id">
            {{ situation.name }}
          </option>
        </b-select>
      </b-field>

      <b-field expanded label="Cidade">
        <b-autocomplete
          clearable
          expanded
          class="custom-autocomplete"
          field="city"
          open-on-focus
          placeholder="Cidade"
          keep-first
          v-model="filterCity"
          :data="filteredCities"
          @select="selected => (filters.buyerId = (selected && selected.id) || null)"
        >
          <template v-slot:empty
            >Nenhum resultado para {{ filterCity ? filterCity.toUpperCase() : '' }}</template
          >

          <template v-slot="props">
            <div class="media">
              <div class="media-content city-media">
                {{ capitalize(props.option.city) }}
              </div>

              <div class="media-right state-media">
                {{ props.option.state }}
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>

      <b-field class="break-attachment-field" label="Abertura">
        <b-field>
          <b-datepicker
            editable
            placeholder="Início"
            icon="calendar-today"
            v-mascara:data
            v-model="filters.period.start"
            :max-date="maiorDataInicio"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button
              class="button is-primary br-4 mr-1"
              @click.prevent.stop="insertTodayFilter('start')"
            >
              <b-icon icon="calendar-today" />
              <span>Hoje</span>
            </button>

            <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('start')">
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>

          <b-datepicker
            editable
            placeholder="Fim"
            icon="calendar-today"
            v-mascara:data
            :disabled="desabilitarPeriodo"
            :min-date="dataMinimaFim"
            v-model="filters.period.end"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button
              class="button is-primary br-4 mr-1"
              @click.prevent.stop="insertTodayFilter('end')"
            >
              <b-icon icon="calendar-today" />
              <span>Hoje</span>
            </button>

            <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('end')">
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>
        </b-field>
      </b-field>

      <b-field class="number-field" label="Número">
        <b-input
          custom-class="uppercase"
          placeholder="Número"
          v-model="filters.number"
          @blur="event => converterMaiusculo('number', event.target.value, 'filters')"
        />
      </b-field>

      <b-field grouped class="column-direction-touch filters-buttons-container">
        <button class="button is-primary botoes" @click.stop.prevent="validarFiltros()">
          Filtrar
        </button>

        <button class="button is-primary botoes" @click.stop.prevent="limparFiltros()">
          Limpar
        </button>
      </b-field>
    </b-field>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import campo from '@/mixins/formulario';
import { capitalize } from '@/utils/functions';

export default {
  name: 'PregaoListaFiltrosVendedor',
  mixins: [campo],
  data() {
    const filters = {
      type: 'pregao',
      situation: 'todas',
      buyerId: null,
      state: '',
      period: {
        start: null,
        end: null,
      },
      number: '',
    };
    const situations = [
      { id: 'todas', name: 'Todos' },
      { id: 'posso', name: 'Posso Participar' },
      { id: 'estou', name: 'Estou Participando' },
      { id: 'participei', name: 'Participei e Finalizados' },
    ];

    return {
      capitalize,
      filters,
      situations,
      filterCity: '',
    };
  },
  computed: {
    ...mapState('prefectures', ['prefecturesCities']),
    dataMinimaFim() {
      const start = new Date(this.filters.period.start);
      if (start) {
        start.setDate(start.getDate() + 1);
        return start;
      }
      return null;
    },
    desabilitarPeriodo() {
      return this.filters.period.start === null;
    },
    maiorDataInicio() {
      if (this.filters.period.end) {
        const data = new Date(this.filters.period.end);
        data.setDate(data.getDate() - 1);
        return data;
      }
      return null;
    },
    filteredCities() {
      return this.prefecturesCities.filter(
        option => option.city.toString().toLowerCase().indexOf(this.filterCity.toLowerCase()) >= 0,
      );
    },
  },
  methods: {
    ...mapActions(['filtrarPregoes', 'limparFiltrosPregoes']),
    limparPeriodo() {
      this.filters.period.start = null;
      this.filters.period.end = null;
    },
    validarFiltros() {
      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.start > this.maiorDataInicio
      ) {
        this.limparPeriodo();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }

      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.end < this.dataMinimaFim
      ) {
        this.limparPeriodo();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }

      return this.filtrarPregoes(this.filters);
    },
    limparFiltros() {
      this.filters.type = this.$route.query.tipo;
      this.filters.buyerId = null;
      this.filters.number = '';
      this.filters.period.start = null;
      this.filters.period.end = null;
      this.filters.situation = 'todas';
      this.filterCity = '';
      this.limparFiltrosPregoes();
      this.filtrarPregoes({ type: this.filters.type });
    },
  },
};
</script>

<style lang="scss" scoped>
.city-media {
  white-space: normal;
}
</style>

<style lang="scss">
.custom-autocomplete .dropdown-menu {
  min-width: 250px;

  .dropdown-content {
    max-height: 300px;
    padding: unset;

    a.dropdown-item {
      padding: 0.75rem !important;
    }

    a.dropdown-item:hover {
      background-color: #ededed;
    }
  }
}
</style>
