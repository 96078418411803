<template>
  <header class="navbar-header">
    <b-navbar transparent wrapper-class="container is-fluid header-container">
      <template v-slot:brand>
        <b-navbar-item tag="router-link" :to="{ name: 'Start' }">
          <img :src="logo" alt="SelCorp - Sistema Eletrônico de Licitações" />
        </b-navbar-item>
      </template>

      <template v-slot:[startDynamicSlot]>
        <b-navbar-item v-if="activeUser" tag="router-link" :to="{ name: 'Dashboard' }">
          Dashboard
        </b-navbar-item>

        <b-navbar-dropdown :boxed="!tablet" hoverable label="Pesquisas">
          <b-navbar-item tag="router-link" :to="{ name: 'ItemPesquisa' }">Itens</b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-dropdown :boxed="!tablet" hoverable label="Licitações">
          <b-navbar-item tag="router-link" to="/ofertascompra?tipo=pregao"
            >Pregões Eletrônicos</b-navbar-item
          >
          <b-navbar-item tag="router-link" to="/ofertascompra?tipo=concorrencia"
            >Concorrências Eletrônicas</b-navbar-item
          >
          <b-navbar-item tag="router-link" to="/dispensas">Dispensas</b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-dropdown :boxed="!tablet" hoverable label="Relatórios" v-if="logged">
          <b-navbar-item tag="router-link" class="navbar-item" :to="{ name: 'ListagemPregoes' }"
            >Listagem de Pregões</b-navbar-item
          >

          <b-navbar-item tag="router-link" class="navbar-item" :to="{ name: 'ListagemDispensas' }"
            >Listagem de Dispensas</b-navbar-item
          >
        </b-navbar-dropdown>

        <b-navbar-item
          v-if="activeUser && company.buyer"
          tag="router-link"
          :to="{ name: 'CompanyInviteSuppliers', params: { id: company.id } }"
          >Convidar Fornecedores</b-navbar-item
        >

        <b-navbar-item tag="router-link" :to="{ name: 'SobreNos' }">Sobre Nós</b-navbar-item>
      </template>

      <template v-slot:[endDynamicSlot]>
        <div v-if="logged" class="notification-wrapper">
          <HeaderNotification />
        </div>

        <b-navbar-dropdown
          :boxed="!tablet"
          hoverable
          right
          shadow
          id="header-dropdown"
          v-if="logged"
          label="Perfil"
        >
          <div v-if="company.id && confirmedEmail">
            <b-navbar-item
              id="custom-navbar-item"
              class="item-link"
              tag="router-link"
              :to="{
                name: 'Company',
                params: { id: company.id },
              }"
              >{{ nameToDisplay }}</b-navbar-item
            >
            <hr class="navbar-divider" />
          </div>

          <div v-if="confirmedEmail">
            <b-navbar-item
              class="item-link"
              tag="router-link"
              :to="{ name: 'Profile', params: { id: user.id } }"
              >{{ userName }}</b-navbar-item
            >
            <hr class="navbar-divider" />
          </div>

          <div v-if="user.empresas && user.empresas.length > 1">
            <b-navbar-item class="item-link" tag="a" @click.prevent="handleChangeCompany"
              >Trocar de {{ company.buyer ? 'órgão público' : 'empresa' }}</b-navbar-item
            >
            <hr class="navbar-divider" />
          </div>

          <div
            v-if="
              confirmedEmail &&
              !company.buyer &&
              ['CADASTRADA', 'PRIMEIRA-VALIDACAO'].indexOf(company.status) === -1
            "
          >
            <b-navbar-item class="item-link" tag="a" @click.prevent="handleGoToRegister">
              Cadastrar nova empresa
            </b-navbar-item>
            <hr class="navbar-divider" />
          </div>

          <b-navbar-item class="item-link" tag="a" @click.prevent="handleLogout"
            >Sair</b-navbar-item
          >
        </b-navbar-dropdown>

        <b-navbar-item v-else>
          <b-field grouped>
            <p v-if="renderEnterButton" class="control">
              <b-button outlined type="is-primary" @click.prevent="isLogging = true"
                >Entrar</b-button
              >
            </p>

            <p v-if="$route.path !== '/pre-cadastro'" class="control">
              <b-button outlined type="is-primary" tag="router-link" to="/pre-cadastro">
                Cadastre-se
              </b-button>
            </p>
          </b-field>
        </b-navbar-item>
      </template>
    </b-navbar>

    <b-notification
      aria-close-label="Close notification"
      class="custom-notification"
      type="is-warning"
      v-if="globalNotification"
      :closable="false"
    >
      {{ globalNotification }}
    </b-notification>

    <b-modal has-modal-card :active.sync="isLogging" @close="cancelLogin">
      <LoginForm v-if="isLogging" />
    </b-modal>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import LoginForm from '@/views/User/Login';
import logo from '@/assets/logosel.png';

import HeaderNotification from '../HeaderNotification';

export default {
  name: 'TheHeader',
  components: {
    HeaderNotification,
    LoginForm,
  },
  data() {
    return {
      isLogging: false,
      logo,
      navbarOpen: false,
    };
  },
  computed: {
    ...mapState('adminEvents', ['globalNotification']),
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapGetters('app', { tablet: 'tablet' }),
    ...mapGetters({
      confirmedEmail: 'getConfirmedUserEmail',
      logged: 'getLogged',
      user: 'getUser',
    }),
    activeUser() {
      return this.logged && this.user && this.user.empresas && this.user.empresas.length;
    },
    companyName() {
      return this.company.socialDenomination ? this.company.socialDenomination : '';
    },
    endDynamicSlot() {
      return this.tablet ? 'start' : 'end';
    },
    nameToDisplay() {
      return this.company.id ? this.companyName : this.userName;
    },
    renderEnterButton() {
      return !this.logged && this.$route.path !== '/entrar';
    },
    startDynamicSlot() {
      return this.tablet ? 'end' : 'start';
    },
    userName() {
      return this.user.nome ? this.user.nome : '';
    },
  },
  methods: {
    ...mapActions('adminEvents', ['fetchActiveEvent']),
    ...mapActions(['logout', 'chooseCompanyUser']),
    cancelLogin() {
      if (this.logged && !this.company.id) {
        this.logout();
      }
    },
    openNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    handleLogout() {
      if (this.logged) {
        this.logout();
      }
    },
    handleChangeCompany() {
      this.isLogging = true;
      this.chooseCompanyUser(true);
    },
    handleGoToRegister() {
      const registerPath = '/cadastrar';
      if (this.$route.path !== registerPath) {
        this.$router.push({ path: registerPath });
      }
    },
  },
  async created() {
    await this.fetchActiveEvent();
  },
};
</script>

<style lang="scss">
#custom-navbar-item {
  max-width: 300px;
  white-space: normal;
  width: max-content;
}

#header-dropdown {
  .navbar-dropdown {
    max-width: 300px;
    min-width: 190px;
  }
}

.custom-notification {
  border-radius: 0;
  font-weight: 800;
}

.item-link {
  padding-right: 1rem !important;
}

.navbar-header {
  position: relative;
  z-index: 9;
}

.notification-wrapper {
  align-items: center;
  display: flex;
  margin-right: 1rem;
}

@media (max-width: 1023px) {
  .header-container .navbar-menu {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin: 0.5rem 0 1rem;
  }

  .navbar-menu a {
    background-color: unset !important;
  }
}
</style>
