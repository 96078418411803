<template>
  <div>
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container is-title-fullhd">
          <div class="level">
            <div class="level-left">
              <div>
                <h1 class="title">Editar {{ tipo }}</h1>
              </div>
            </div>

            <div v-if="situacao > 1" class="custom-level-center">
              <div class="box">
                <div class="is-flex warning-message-container">
                  <b-icon
                    class="rm-1 custom-warning-icon"
                    icon="information"
                    type="is-info"
                    size="is-medium"
                  ></b-icon>
                  <p>
                    <strong>Atenção!</strong> Qualquer alteração realizada nas informações pós
                    publicação irá notificar todos os participantes.
                  </p>
                </div>
              </div>
            </div>

            <div class="level-end has-text-centered tm1">
              <div>
                <a
                  v-if="situacao < 2"
                  class="action-buttons button is-primary is-inverted is-outlined"
                  @click.prevent.stop="publicar()"
                  >Publicar</a
                >

                <a
                  v-if="situacao === 1"
                  class="action-buttons button is-danger is-outlined"
                  :class="{ ' is-loading': loading.excluir }"
                  @click.prevent.stop="excluir"
                  >Excluir</a
                >

                <a
                  v-if="displayCancelOrRevokeAuction"
                  class="action-buttons button is-primary is-inverted is-outlined"
                  :class="{ ' is-loading': loading.revogar }"
                  @click.prevent.stop="openModal(ofertaCompra.id, 'revoke')"
                  >Revogar</a
                >

                <a
                  v-if="displayCancelOrRevokeAuction"
                  class="action-buttons button is-primary is-inverted is-outlined"
                  :class="{ ' is-loading': loading.revogar }"
                  @click.prevent.stop="openModal(ofertaCompra.id, 'cancel')"
                  >Anular</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns column-direction-touch">
          <div class="column is-narrow">
            <MenuSecundario :titulo="tipo" :itens="menu" />
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justification.active"
      @justificate="sendJustification"
    >
      <AppJustification
        v-if="justification.active"
        :failText="justification.failText"
        :displayFailButton="justification.displayFail"
        :displaySuccessButton="justification.displaySuccess"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { funcoes } from '@/utils/enumerados';

import AppJustification from '@/components/AppJustification';
import MenuSecundario from '@/components/MenuSecundario';

export default {
  name: 'PregaoEditar',
  components: {
    AppJustification,
    MenuSecundario,
  },
  data() {
    const loading = {
      cancel: false,
      delete: false,
      publish: false,
      revoke: false,
    };
    const justification = {
      active: false,
      displayFail: false,
      displaySuccess: false,
      failText: '',
    };

    return {
      auctionId: 0,
      justification,
      loading,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.limparPregao();
    this.limparAnexosPregao();
    this.limparItensPregao();
    this.limparResponsaveisPregao();
    return next();
  },
  computed: {
    ...mapActions('documentTypes', ['fetchDocumentTypes']),
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
      funcao: 'getFunction',
      anexos: 'obterOfertaCompraAnexos',
      itens: 'obterTodosItens',
      responsaveis: 'obterOfertaCompraResponsaveis',
    }),
    tipo() {
      if (this.ofertaCompra.tipo === 'PREGAO') {
        return 'Pregão';
      }
      return 'Concorrência';
    },
    situacao() {
      return this.ofertaCompra.situacao.id;
    },
    displayCancelOrRevokeAuction() {
      return this.autoridade && [2, 3, 4, 15, 16, 17].indexOf(this.situacao) > -1;
    },
    menu() {
      return [
        { titulo: 'Dados Cadastrais', rota: 'PregaoEditarDados' },
        { titulo: 'Anexos', rota: 'PregaoEditarAnexos' },
        { titulo: 'Itens', rota: 'PregaoEditarItens' },
        { titulo: 'Documentos Requeridos', rota: 'AuctionRequiredDocuments' },
        { titulo: 'Responsáveis', rota: 'PregaoEditarResponsaveis' },
      ];
    },
    autoridade() {
      return this.funcao === funcoes.AUTORIDADE;
    },
  },
  methods: {
    ...mapActions('requiredDocuments', ['fetchRequiredDocuments']),
    ...mapActions([
      'alternarEmPublicacaoPregao',
      'buscarOfertaCompra',
      'buscarOfertaCompraAnexos',
      'buscarItens',
      'buscarOfertaCompraResponsaveis',
      'cancelAuction',
      'editAuction',
      'excluirOfertaCompra',
      'limparPregao',
      'limparAnexosPregao',
      'limparItensPregao',
      'limparResponsaveisPregao',
      'publishAuction',
      'revokeAuction',
      'validarOfertaCompra',
      'validarOfertaCompraAnexos',
      'validarItensBeforePublish',
      'validarOfertaCompraResponsaveis',
    ]),
    async cancelElectronicAuction(dados) {
      this.loading.cancel = true;
      try {
        await this.cancelAuction(dados);
        this.$alerta(`${this.tipo} eletrônico(a) anulado`, 'is-success');
        this.$router.push({
          name: 'Pregoes',
          query: { tipo: this.ofertaCompra.tipo?.toLowerCase() },
        });
      } catch (error) {
        this.$alerta(`Erro ao anular ${this.tipo} eletrônico(a)`, 'is-danger');
      }
      this.loading.cancel = false;
      this.$refs.justificationModal.close();
    },
    async publicar() {
      this.alternarEmPublicacaoPregao(true);
      const valida = await this.validar();

      if (valida) {
        const confirmation = await this.$confirmacao({
          message: `Você está publicando o ${this.tipo} Eletrônico(a) <strong>${this.ofertaCompra.numero_oc}</strong>. Deseja continuar?`,
          type: 'is-info',
        });
        if (!confirmation) {
          this.alternarEmPublicacaoPregao(false);
          return;
        }

        this.loading.publicar = true;

        try {
          await this.publishAuction();
          this.$alerta(`${this.tipo} eletrônico(a) publicado`, 'is-success');
          this.$router.push({
            name: 'Pregoes',
            query: { tipo: this.ofertaCompra.tipo?.toLowerCase() },
          });
        } catch (error) {
          this.$alerta(`Erro ao publicar ${this.tipo} eletrônico(a)`, 'is-danger');
        }
        this.alternarEmPublicacaoPregao(false);
      }
      this.loading.publicar = false;
    },
    openModal(auctionId, action) {
      if (action === 'cancel') {
        this.justification.failText = 'Anular';
      }
      if (action === 'revoke') {
        this.justification.failText = 'Revogar';
      }

      this.action = action;
      this.auctionId = auctionId;
      this.justification.active = true;
      this.justification.displayFail = true;
      this.justification.displaySuccess = false;
    },
    async excluir() {
      const message =
        this.ofertaCompra.tipo === 'PREGAO'
          ? 'Tem certeza que deseja excluir esse pregão eletrônico?'
          : 'Tem certeza que deseja excluir esta concorrência eletrônica?';
      const confirmacao = await this.$confirmacao({
        message,
      });
      if (confirmacao) {
        this.loading.excluir = true;
        try {
          await this.excluirOfertaCompra(this.ofertaCompra.id);
          this.$router.push({
            name: 'Pregoes',
            query: { tipo: this.ofertaCompra.tipo?.toLowerCase() },
          });
        } catch (error) {
          this.$alerta(`Erro ao excluir ${this.tipo}.`, 'is-danger');
        } finally {
          this.loading.excluir = false;
        }
      }
    },
    async sendJustification({ justification }) {
      if (this.action === 'cancel') {
        await this.cancelElectronicAuction({
          auctionId: this.auctionId,
          justification,
        });
      }
      if (this.action === 'revoke') {
        await this.revokeElectronicAuction({
          auctionId: this.auctionId,
          justification,
        });
      }
    },
    async revokeElectronicAuction(dados) {
      this.loading.revoke = true;
      try {
        await this.revokeAuction(dados);
        this.$alerta(`${this.tipo} eletrônico(a) revogado`, 'is-success');
        this.$router.push({
          name: 'Pregoes',
          query: { tipo: this.ofertaCompra.tipo?.toLowerCase() },
        });
      } catch (error) {
        this.$alerta(`Erro ao revogar ${this.tipo} eletrônico(a)`, 'is-danger');
      }
      this.loading.revoke = false;
      this.$refs.justificationModal.close();
    },
    async validar() {
      let valida = await this.validarOfertaCompra();
      if (!valida && this.$route.name !== 'PregaoEditarDados') {
        await this.buscarOfertaCompra(this.$route.params.id);
      }

      valida = await this.validarOfertaCompra();

      if (!valida) {
        this.$alerta(`Informe os dados do ${this.tipo} eletrônico(a)`, 'is-danger');
        if (this.$route.name !== 'PregaoEditarDados') {
          await this.$router.push({ name: 'PregaoEditarDados' });
        }
        this.alternarEmPublicacaoPregao(true);

        return valida;
      }

      if (
        !this.anexos.some(anexo => anexo.tipo === 'EDITAL') &&
        this.$route.name !== 'PregaoEditarAnexos'
      ) {
        await this.buscarOfertaCompraAnexos(this.$route.params.id);
      }

      valida = await this.validarOfertaCompraAnexos();
      if (!valida) {
        this.$alerta('Inserção de Edital é obrigatória', 'is-danger');
        if (this.$route.name !== 'PregaoEditarAnexos') {
          await this.$router.push({ name: 'PregaoEditarAnexos' });
        }
        return valida;
      }

      if (!this.itens.length && this.$route.name !== 'PregaoEditarItens') {
        await this.buscarItens({ ofertaCompraId: this.$route.params.id, v2: this.$route.query.v2 });
      }

      const result = await this.validarItensBeforePublish();
      valida = result.valid;
      if (!valida) {
        this.$alerta(result.errorMessage, 'is-danger');
        if (this.$route.name !== 'PregaoEditarItens') {
          await this.$router.push({ name: 'PregaoEditarItens' });
        }
        return valida;
      }

      if (!this.requiredDocuments.length && this.$route.name !== 'AuctionRequiredDocuments') {
        await this.fetchRequiredDocuments(this.$route.params.id);
      }

      valida = this.requiredDocuments.length;
      if (!valida) {
        this.$alerta('Insira ao menos um documento requerido', 'is-danger');
        if (this.$route.name !== 'AuctionRequiredDocuments') {
          await this.$router.push({ name: 'AuctionRequiredDocuments' });
        }
        return valida;
      }

      if (!this.responsaveis.length && this.$route.name !== 'PregaoEditarResponsaveis') {
        await this.buscarOfertaCompraResponsaveis(this.$route.params.id);
      }

      valida = await this.validarOfertaCompraResponsaveis();
      if (!valida) {
        this.$alerta(`Informe os responsáveis do ${this.tipo} Eletrônico(a)`, 'is-danger');
        await this.redirecionarParaResponsaveis();
        return false;
      }

      const existeAutoridade = this.responsaveis.some(
        responsavel => responsavel.funcao === 'AUTORIDADE',
      );
      if (!existeAutoridade) {
        this.$alerta('Insira a Autoridade competente', 'is-danger');
        await this.redirecionarParaResponsaveis();
        return false;
      }

      const existePregoeiro = this.responsaveis.some(
        responsavel => responsavel.funcao === 'PREGOEIRO',
      );
      if (!existePregoeiro) {
        this.$alerta('Insira o Pregoeiro', 'is-danger');
        await this.redirecionarParaResponsaveis();
        return false;
      }
      return valida && existeAutoridade && existePregoeiro;
    },
    redirecionarParaResponsaveis() {
      if (this.$route.name !== 'PregaoEditarResponsaveis') {
        this.$router.push({ name: 'PregaoEditarResponsaveis' });
      }
    },
  },
  async created() {
    await this.buscarOfertaCompraResponsaveis(this.$route.params.id);
  },
  beforeDestroy() {
    this.alternarEmPublicacaoPregao(false);
  },
};
</script>

<style scoped>
.action-buttons {
  margin-right: 0.5rem;
}

.custom-level-center {
  margin: 1rem auto;
  max-width: 45%;
}

.custom-warning-icon {
  vertical-align: middle;
}

.rm-1 {
  margin-right: 0.5rem;
}

.tm1 {
  margin-top: 1rem;
}

.warning-message-container {
  align-items: center;
  font-size: 0.96rem;
}

@media (max-width: 768px) {
  .custom-level-center {
    max-width: 80%;
  }
}
</style>
