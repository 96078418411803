<template>
  <div>
    <div class="box custom-box">
      <p class="declaration mb-1">
        <strong>Requisitos de Habilitação:</strong> Declaro que cumprimos plenamente os requisitos
        de habilitação e que a(s) proposta(s) estão em conformidade com o edital deste(a)
        {{ auctionType }}.
      </p>

      <p v-if="exclusiveNotice" class="declaration">
        <strong>Preferência local/regional:</strong> Declaro que a empresa atende aos requisitos
        para direito de preferência local/regional conforme parágrafo 3° do artigo 48 da lei
        123/2006.
      </p>
    </div>

    <section class="section" v-if="!auctionDeclarations.length">
      <div class="content has-text-grey has-text-centered">
        <p>Nenhuma declaração.</p>
      </div>
    </section>

    <b-table
      class="table is-fullwidth declarations-table"
      default-sort-direction="ASC"
      v-if="auctionDeclarations.length"
      :data="auctionDeclarations"
    >
      <b-table-column centered label="Fornecedor" v-slot="props">
        {{ props.row.seller }}
      </b-table-column>

      <b-table-column centered label="Porte" v-slot="props">
        {{ formatCompanySize(props.row.companySize) }}
      </b-table-column>

      <b-table-column centered label="Declaração" v-slot="props">
        {{ formatDeclaration(props.row.declaration) }}
      </b-table-column>

      <b-table-column centered sortable field="data" label="Data" v-slot="props">
        <div class="date-column">{{ props.row.createdAt | formatDatetime }}</div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  formatarDataSegundo as formatDatetime,
  formatarValor as formatValue,
  formatarPorcentagem as formatPercentage,
} from '@/utils/format';

export default {
  name: 'DeclarationsList',
  props: {
    item: { type: Object, default: () => {} },
    formatCompanySize: { type: Function, default: () => {} },
  },
  data() {
    return {
      formatPercentage,
      formatValue,
    };
  },
  filters: {
    formatDatetime,
  },
  computed: {
    ...mapGetters('auctionsDeclarations', {
      auctionDeclarations: 'getDeclarations',
    }),
    ...mapGetters({
      auction: 'obterOfertaCompra',
    }),
    auctionType() {
      if (this.auction.tipo === 'PREGAO') {
        return 'Pregão Eletrônico';
      }

      return 'Concorrência Eletrônica';
    },
    exclusiveNotice() {
      return this.auction.tipo_edital === 'RESERVADO';
    },
  },
  methods: {
    formatDeclaration(declaration) {
      return {
        REQUISITOS: 'Requisitos de Habilitação',
        'PREFERENCIA-REGIONAL': 'Preferência Local/Regional',
      }[declaration];
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-box {
  background-color: #ececec;
}

.date-column {
  min-width: fit-content;
}

.declaration {
  font-size: 0.96rem;
}

@media (max-width: 1500px) {
  .declarations-table {
    font-size: 0.9rem;
  }
}
</style>
