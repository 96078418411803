<template>
  <div>
    <div class="terms" v-if="displayTerms">
      <b-checkbox
        :disabled="disableRequirementsDeclaration"
        v-model="declarations.requirements"
        @input.native="event => createDeclaration('requirements', event.target.checked)"
        >Declaro que cumprimos plenamente os requisitos de habilitação e que a(s) proposta(s) estão
        em conformidade com o edital deste(a) {{ auctionType }}.</b-checkbox
      >

      <b-checkbox
        class="check-regional-preference"
        v-if="company.preference && regionalPreferenceEnabled"
        v-model="declarations.regionalPreference"
        @input.native="event => createDeclaration('regionalPreference', event.target.checked)"
        >Declaro que a empresa atende aos requisitos para direito de preferência local/regional
        conforme parágrafo 3° do artigo 48 da lei 123/2006.</b-checkbox
      >
    </div>

    <section>
      <div class="columns">
        <div class="column has-text-right">
          <div v-if="crier">
            <button
              v-if="verifySituation(5)"
              class="button field is-primary actions"
              :class="{ ' is-loading': loading.classificarItens }"
              :disabled="!selectedItems.length"
              @click="classificarPropostasItens"
            >
              <span>Classificar</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <ImportAuctionProposals @mode="value => (mode = value)" />

    <div v-if="!items.length" class="panel">
      <div class="panel-block centered-content">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>{{ emptyItems }}.</p>
          </div>
        </section>
      </div>
    </div>

    <b-table
      hoverable
      class="table is-fullwidth items-table"
      detail-key="id"
      ref="tabela"
      v-if="items.length"
      :data="items"
      :checkable="crier && verifySituation(5)"
      :checked-rows.sync="selectedItems"
      :is-row-checkable="row => checkableItems(row)"
      :detailed="detailed"
      :opened-detailed.sync="detailsList"
      @details-open="canSendProposal"
    >
      <b-table-column centered width="60" :visible="exclusiveItem" label="Exclusivo" v-slot="props">
        <b-icon
          custom-size="mdi-24px"
          icon="check"
          type="is-success"
          v-show="props.row.item.exclusivo"
        />
      </b-table-column>

      <b-table-column centered label="ID" width="40" field="identificador" v-slot="props">{{
        props.row.identificador
      }}</b-table-column>

      <b-table-column
        centered
        field="unidade"
        label="Unidade"
        v-slot="props"
        :visible="auctionSituation < 6"
        ><div class="unity-column">{{ props.row.unidade.nome }}</div></b-table-column
      >

      <b-table-column
        cell-class="elipsis-size"
        label="Descrição"
        v-slot="props"
        :key="descriptionKey"
        :ref="'description-column'"
      >
        <div
          class="elipsis-column"
          :class="{
            'cursor-pointer': displayEyeIcon(props.row.id),
            'justify-align': justify,
          }"
          :ref="`description-container-${props.row.id}`"
          @click.stop.prevent="openDescription(props.row.id)"
        >
          <b-icon
            class="custom-eye-icon mr-1"
            icon="eye-outline"
            v-if="displayEyeIcon(props.row.id)"
          ></b-icon>

          <a
            :class="{ 'justify-align': justify }"
            :ref="`description-link-${props.row.id}`"
            v-if="displayEyeIcon(props.row.id)"
            >{{ props.row.item.descricao }}</a
          >
          <p v-else>{{ props.row.item.descricao }}</p>
        </div>
      </b-table-column>

      <b-table-column :visible="auctionSituation < 6" centered label="Quantidade" v-slot="props">{{
        props.row.quantidade | formatarNumero
      }}</b-table-column>

      <b-table-column centered label="Referência" v-slot="props" :visible="displayReferralColumn"
        ><div v-if="!props.row.referencia && !props.row.total">
          <b-tag type="is-dark" size="is-small">VIDE EDITAL</b-tag>
        </div>
        <div v-else>{{ formatReference(props.row) | formatarValor }}</div></b-table-column
      >

      <b-table-column :visible="displayTotalReferential" centered label="Total" v-slot="props">
        <div v-if="!props.row.referencia && !props.row.total">
          <b-tag type="is-dark" size="is-small">VIDE EDITAL</b-tag>
        </div>

        <div v-else>{{ displayTotalReferential ? formatarValor(props.row.total) : 0 }}</div>
      </b-table-column>

      <b-table-column centered label="Redução" v-slot="props" :visible="displayReductionColumn"
        ><b-tooltip
          v-if="biggestDiscount && props.row.referencia && props.row.total"
          size="is-medium"
          :label="formatarValor(props.row.reducaoLances)"
          >{{ formatPercentageReduction(props.row) }}</b-tooltip
        >

        <div v-else-if="biggestDiscount">
          {{ formatPercentageReduction(props.row) }}
        </div>

        <div v-else>
          {{ props.row.reducaoLances | formatarValor }}
        </div></b-table-column
      >

      <b-table-column
        centered
        class="lower-bid-column"
        v-slot="props"
        :label="biggestDiscount ? 'Maior Desconto' : 'Menor Lance'"
        :visible="auctionSituation > 5"
      >
        <div class="has-text-centered" :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <b-tooltip
            v-if="biggestDiscount && props.row.referencia && props.row.total"
            size="is-medium"
            class="discount-tooltip"
            :type="lowerBidColor(props.row)"
            :label="
              props.row.menor_lance.percentual !== null
                ? formatMinorBid(props.row)
                : 'Nenhum Fornecedor'
            "
          >
            <span
              v-if="auctionSituation > 5"
              class="tag tag-extended"
              :class="lowerBidColor(props.row)"
              >{{ formatDiscountPercentage(props.row) }}</span
            >
          </b-tooltip>

          <div v-else>
            <span
              v-if="auctionSituation > 5"
              class="tag tag-extended"
              :class="lowerBidColor(props.row)"
              >{{
                biggestDiscount
                  ? formatDiscountPercentage(props.row)
                  : formatarValor(minorBidExists(props.row))
              }}</span
            >
          </div>

          <b-tooltip
            v-if="biggestDiscount && props.row.referencia && props.row.total"
            size="is-medium"
            type="is-light"
            class="discount-tooltip"
            :label="
              props.row.preferencia.percentual !== null
                ? formatPreferenceBid(props.row)
                : 'Nenhum Fornecedor'
            "
          >
            <span
              class="tag tag-extended"
              v-if="displayPreference(props.row.preferencia.fornecedor)"
              >{{ formatPreferenceDiscount(props.row) }}</span
            >
          </b-tooltip>

          <div v-else>
            <span
              class="tag tag-extended"
              v-if="displayPreference(props.row.preferencia.fornecedor)"
              >{{
                biggestDiscount
                  ? formatPreferenceDiscount(props.row)
                  : formatarValor(preferenceValueExists(props.row))
              }}</span
            >
          </div>
        </div>
      </b-table-column>

      <b-table-column centered label="Fornecedor" :visible="auctionSituation > 5" v-slot="props">
        <div class="has-text-centered" :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <span v-if="!displayCompany" class="tag tag-extended" :class="lowerBidColor(props.row)">{{
            bidderExists(props.row)
          }}</span>
          <span class="tag is-success tag-extended" v-else>{{
            props.row.menor_lance.razao_social
          }}</span>

          <span
            class="tag tag-extended"
            v-if="displayPreference(props.row.preferencia.fornecedor)"
            >{{ preferenceBidExists(props.row) }}</span
          >
        </div>
      </b-table-column>

      <b-table-column centered label="Porte" :visible="displaySizeColumn" v-slot="props">
        <div class="has-text-centered" :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <div class="company-size-text mr-1">
            {{ formatWinnerCompanySize(props.row) }}
          </div>

          <span class="tag small" v-if="displayPreference(props.row.preferencia.fornecedor)">{{
            preferenceSizeExists(props.row)
          }}</span>
        </div>
      </b-table-column>

      <b-table-column
        centered
        width="120"
        label="Percentual"
        v-slot="props"
        :visible="[6, 7, 8].indexOf(auctionSituation) > -1 && biggestDiscount"
      >
        <PregaoPercentual
          :ref="`percentual-${props.index}`"
          :item="props.row"
          :lances-fechados-em-envio="closedBidsOnSubmission"
          :valores="bidValues"
          @enviar="informPIN"
          @lance="addBid"
          @aviso="displayPercentageWarning"
        />
      </b-table-column>

      <b-table-column
        centered
        width="140"
        v-slot="props"
        :visible="displayValueColumn"
        :label="bidColumnLabel"
      >
        <PregaoValor
          :ref="`valor-${props.index}`"
          :item="props.row"
          :lances-fechados-em-envio="closedBidsOnSubmission"
          :valores="bidValues"
          @enviar="informPIN"
          @lance="addBid"
          @aviso="displayPercentageWarning"
        />
      </b-table-column>

      <b-table-column centered label="Estado" v-slot="props" :visible="displayStatusColumn">
        <span
          class="tag is-primary tag-extended"
          :class="props.row.estado | estadoItem(auction.modo_disputa)"
          >{{ props.row.estado | tituloEstadoItem(auction.modo_disputa) }}</span
        >
      </b-table-column>

      <b-table-column centered label="Ações" v-slot="props" :visible="displayActions">
        <button
          class="button is-primary actions"
          @click="sendDocuments(props.row)"
          v-if="displaySendDocuments(props.row)"
        >
          Enviar Documentos
        </button>

        <button
          v-if="auctionSituation === 7 && bidder"
          tabindex="-1"
          class="button is-danger is-outlined"
          style="height: 100%"
          :disabled="disable.abdicate"
          @click.stop.prevent="
            verifyPIN(
              props.row.id,
              props.row.identificador,
              props.row.item.descricao,
              props.row.unidade.nome,
            )
          "
        >
          Abdicar Direito
        </button>
      </b-table-column>

      <b-table-column
        centered
        label="Propostas"
        v-slot="props"
        :visible="auctionSituation < 5 && company.id === auction.comprador.id"
        >{{ props.row.propostas ? props.row.propostas : 0 }}</b-table-column
      >

      <b-table-column
        centered
        label="Opções"
        v-slot="props"
        :class="{ 'options-column': auctionSituation >= 9 }"
        :visible="!!displayOptions"
        :width="optionsWidth"
      >
        <b-field
          class="is-justify-content-center"
          v-if="proposalIcon(props.row)"
          id="my-items-options-field"
        >
          <p class="control">
            <button
              :disabled="!requirementsDeclaration"
              class="button is-primary options-button"
              @click.prevent.stop="editProposal(props.row)"
            >
              <b-icon icon="pencil" size="is-small" />
            </button>
          </p>

          <p class="control">
            <button
              :disabled="!requirementsDeclaration || disable.proposalDelete"
              class="button is-danger options-button"
              @click.prevent.stop="requestProposalDelete(props.row)"
            >
              <b-icon icon="delete" size="is-small" />
            </button>
          </p>
        </b-field>
        <b-field class="is-justify-content-center">
          <button
            class="button is-small is-primary is-outlined offers-button"
            tabindex="-1"
            v-if="displayOffersList(props.row.estado)"
            @click.prevent.stop="viewOffers(props.row)"
          >
            Ofertas
          </button>
          <button
            class="button is-primary is-small is-outlined offers-button"
            v-if="allowBidDelete(props.row)"
            @click.prevent.stop="deleteLastBid(props.row)"
          >
            Excluir
          </button>
        </b-field>
      </b-table-column>

      <template v-if="displayFooter" v-slot:footer>
        <div class="footer-container">
          <div class="all-items-button-container has-text-left">
            <button
              class="button is-primary"
              style="height: 100%"
              v-if="displayAllItemsButton"
              @click.stop.prevent="viewAllItems()"
            >
              Todos os Itens
            </button>
          </div>

          <div class="value-warning-container has-text-centered">
            <transition name="fade">
              <b-tag
                v-if="percentageWarning"
                class="value-warning"
                type="is-danger"
                :size="mobile ? 'is-small' : 'is-medium'"
                >{{ percentageWarningText }}</b-tag
              >
            </transition>
          </div>

          <div class="send-button-container has-text-right">
            <button
              v-if="displaySendBidsButton"
              ref="sendBidsButton"
              class="button is-primary"
              style="height: 100%"
              :class="{ ' is-loading': loading.general, 'is-small': mobile }"
              @lance="addBid"
              @click.stop.prevent="informPIN"
            >
              {{ sendBidsButtonText }}
            </button>
          </div>
        </div>
      </template>

      <template v-slot:detail="props">
        <SendProposal
          v-if="[3, 4].indexOf(auctionSituation) > -1"
          :key="selectProposalKey(props.row.id)"
          :item="props.row"
        />
        <ProposalAnalysis v-if="displayProposalAnalysis" :item="props.row" />
      </template>
    </b-table>

    <b-modal has-modal-card :active.sync="validatingPIN" :onCancel="resetLoadings">
      <PinForm v-if="validatingPIN" :reason="reasonPIN" @validar="deleteAuctionProposal()" />
    </b-modal>

    <b-modal
      has-modal-card
      :active.sync="offerConfirmationModal"
      :onCancel="resetLoadings"
      @confirm="sendBids()"
    >
      <AppActionConfirmation
        v-if="displayConfirmation.offer"
        :display="confirmation.offer.display"
        :items="confirmation.offer.items"
        :message="confirmation.offer.message"
        :mobile="mobile"
      />
    </b-modal>

    <b-modal has-modal-card :active.sync="requestPIN" :onCancel="resetLoadings">
      <PinForm v-if="requestPIN" :reason="reasonPIN" @validar="sendBids()" />
    </b-modal>

    <b-modal
      has-modal-card
      :active.sync="preferenceConfirmationModal"
      :onCancel="resetLoadings"
      @confirm="preferenceAbdicate()"
    >
      <AppActionConfirmation
        v-if="displayConfirmation.preference"
        :confirm-type="confirmation.preference.confirmType"
        :iconType="confirmation.preference.iconType"
        :display="confirmation.preference.display"
        :items="confirmation.preference.items"
        :message="confirmation.preference.message"
        :mobile="mobile"
      />
    </b-modal>

    <b-modal has-modal-card :active.sync="preferenceAbdicatePin" :onCancel="resetLoadings">
      <PinForm v-if="preferenceAbdicatePin" :reason="reasonPIN" @validar="preferenceAbdicate()" />
    </b-modal>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificar"
      :onCancel="resetLoadingClassificacao"
      @justificate="classificarPropostas"
    >
      <AppJustification v-if="justificar" />
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';

import { mapActions, mapGetters, mapState } from 'vuex';

import {
  enviarLance,
  enviarPreferencia,
  enviarLanceNegociacao,
  excluirUltimoLance,
} from '@/resources/ofertasCompra';
import { verificarPregoeiro, verificarFornecedor, verificarLicitante } from '@/utils/enumerados';
import { formatarValor } from '@/utils/format';

import auctionItems from '@/mixins/auctionItems';

import AppActionConfirmation from '@/components/AppActionConfirmation';
import PinForm from '@/views/User/Pin';
import PregaoPercentual from '@/views/Pregao/AuctionContainer/PregaoPercentual';
import PregaoValor from '@/views/Pregao/AuctionContainer/PregaoValor';
import ProposalAnalysis from '@/views/Pregao/AuctionContainer/Proposals/ProposalAnalysis';
import SendProposal from '@/views/Pregao/AuctionContainer/Proposals/SendProposal';

import ImportAuctionProposals from '../Proposals/ImportAuctionProposals';
import AppJustification from '../../../../components/AppJustification';

export default {
  name: 'MyItems',
  components: {
    AppActionConfirmation,
    AppJustification,
    ImportAuctionProposals,
    PinForm,
    PregaoPercentual,
    PregaoValor,
    ProposalAnalysis,
    SendProposal,
  },
  mixins: [auctionItems],
  props: {
    loaded: Boolean,
  },
  data() {
    const data = {
      itemId: 0,
      propostaId: 0,
    };
    const declarations = {
      requirements: false,
      regionalPreference: false,
    };
    const disable = {
      abdicate: false,
      proposalDelete: false,
    };
    const displayConfirmation = {
      offer: false,
      preference: false,
    };
    const confirmation = {
      offer: {},
      preference: {},
    };
    const loading = {
      classificarItens: false,
    };

    const fifteenSecondsAgo = { value: new Date().getTime() - 15000 };
    const auctionType = '';
    return {
      auctionType,
      abdicatePreferenceData: {},
      bids: [],
      bidValues: {},
      closedBidsOnSubmission: [],
      componentKey: 0,
      confirmation,
      data,
      descriptionKey: 0,
      declarations,
      detailsList: [],
      disable,
      fifteenSecondsAgo,
      displayConfirmation,
      displayDeleteBidButton: false,
      formatarValor,
      mode: '',
      offerConfirmationModal: false,
      preferenceConfirmationModal: false,
      percentageWarning: false,
      preferenceAbdicatePin: false,
      justificar: false,
      reasonPIN: '',
      requestPIN: false,
      validatingPIN: false,
      valuesNegotiationList: [],
      selectedItems: [],
      loading,
    };
  },
  watch: {
    auctionDeclarations: {
      immediate: true,
      handler() {
        this.declarations.requirements = this.auctionDeclarations.some(
          element => element.declaration === 'REQUISITOS',
        );
        this.declarations.regionalPreference = this.auctionDeclarations.some(
          element => element.declaration === 'PREFERENCIA-REGIONAL',
        );
      },
    },
    loaded() {
      if (this.loaded) {
        this.verifyOverflowed();
      }
    },
  },
  proposals: {
    deep: true,
    handler() {
      if (this.mode === 'import') this.componentKey += 1;
    },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('auctionsBidders', ['userLoggedBidder']),
    ...mapGetters({
      allItems: 'obterTodosItens',
      auctionBids: 'obterOfertaCompraLances',
      filteredItems: 'obterItens',
      user: 'getUser',
      function: 'getFunction',
      validPin: 'getValidPin',
    }),
    ...mapGetters('auctionsDeclarations', {
      auctionDeclarations: 'getDeclarations',
      auctionDeclarationsLoaded: 'getDeclarationsLoaded',
    }),
    ...mapGetters('proposals', { proposals: 'obterPropostasUsuarioLogado' }),
    allClosedSent() {
      const filteredBids = this.auctionBids.flatMap(lance =>
        this.items.filter(
          elemento => elemento.id === lance.ofertaCompraItemId && lance.tipo === 'FECHADO',
        ),
      );
      return filteredBids.length === this.filteredItems.length;
    },
    bidColumnLabel() {
      if (this.auctionSituation === 8) return 'Proposta';
      if ([6, 7].indexOf(this.auctionSituation) > -1 && this.auction.tipo_negociacao.id === 1) {
        return 'Valor Unitário';
      }
      return 'Valor Total';
    },
    closedMode() {
      return this.items.some(
        item => ['LANCE-FECHADO', 'PRIMEIRA-CHAMADA', 'NOVA-CHAMADA'].indexOf(item.estado) > -1,
      );
    },
    detailed() {
      if (this.company.status !== 'HABILITADA' && !this.bidder) return false;
      if (!this.operateAuctions) return false;
      return (
        ([3, 4].indexOf(this.auctionSituation) > -1 && this.requirementsDeclaration) ||
        (this.auctionSituation === 5 && this.auction.comprador.id === this.company.id)
      );
    },
    displayProposalAnalysis() {
      return this.auctionSituation === 5 && this.auction.comprador.id === this.company.id;
    },
    disableRequirementsDeclaration() {
      return this.proposals.length > 0;
    },
    displayActions() {
      return (
        (this.auctionSituation === 7 && this.bidder) ||
        ((this.auctionSituation === 8 || this.auctionSituation === 9) && this.crier) ||
        (this.auctionSituation === 9 &&
          this.items.some(
            item =>
              item.menor_lance.fornecedor === this.userLoggedBidder.alias &&
              item.estado === 'HABILITACAO',
          ))
      );
    },
    displayAllItemsButton() {
      return (
        [6, 7, 8, 9].indexOf(this.auctionSituation) > -1 &&
        this.bidder &&
        this.filteredItems.length < this.allItems.length
      );
    },
    displayOptions() {
      return (
        (this.auctionSituation === 3 && this.proposals.length) ||
        (this.auctionSituation > 5 && this.auctionSituation < 12)
      );
    },
    displaySendBidsButton() {
      return (
        [7, 8].indexOf(this.auctionSituation) > -1 ||
        (this.auctionSituation === 6 && !this.allClosedSent)
      );
    },
    displayTerms() {
      return (
        this.auctionSituation === 3 &&
        this.company.status === 'HABILITADA' &&
        (this.provider || this.bidder) &&
        this.auctionDeclarationsLoaded
      );
    },
    displayTotalReferential() {
      return this.authorizedBuyer && this.auctionSituation === 5 && this.negotiationType === 1;
    },
    displayValueColumn() {
      if ([6, 7, 8].indexOf(this.auctionSituation) > -1) {
        if (this.biggestDiscount) {
          const itemReferential = this.filteredItems.some(item => item.total && item.referencia);

          if (!itemReferential) return false;
        }

        return true;
      }
      return false;
    },
    emptyItems() {
      switch (this.auctionSituation) {
        case 6:
          return 'Você não tem itens para enviar lance.';
        case 7:
          return 'Você não tem itens para exercer preferência.';
        default:
          return 'Aguarde o pregoeiro iniciar a próxima etapa do pregão eletrônico.';
      }
    },
    getReasonPIN() {
      return {
        6: 'PREGAO-LANCE',
        7: 'PREGAO-PREFERENCIA',
        8: 'PREGAO-NEGOCIACAO',
      }[this.auctionSituation];
    },
    items() {
      if (
        verificarFornecedor(this.function) ||
        (verificarLicitante(this.function) && [3, 4].indexOf(this.auctionSituation) > -1)
      ) {
        return this.allItems;
      }

      if (verificarPregoeiro(this.function) && this.auctionSituation === 5) {
        return this.allItems;
      }

      return this.filteredItems;
    },
    openItem() {
      return this.items.some(
        item =>
          [
            'LANCE',
            'LANCE-RANDOMICO',
            'LANCE-FECHADO',
            'PREFERENCIA-INICIADA',
            'NEGOCIACAO',
          ].indexOf(item.estado) > -1,
      );
    },
    operateAuctions() {
      return this.user.empresas.some(
        userCompany => userCompany.id === this.company.id && userCompany.operar_pregoes,
      );
    },
    optionsWidth() {
      if (this.auctionSituation > 8 && this.bidder) return '190';
      return '110';
    },
    percentageWarningText() {
      if (this.biggestDiscount) return '20% maior que o maior desconto';
      return '20% menor que o menor lance';
    },
    requirementsDeclaration() {
      return this.auctionDeclarations.some(element => element.declaration === 'REQUISITOS');
    },
    sendBidsButtonText() {
      if (this.auctionSituation === 6) {
        return 'Enviar Lance(s)';
      }
      if (this.auctionSituation === 7) {
        return 'Enviar Preferência(s)';
      }
      if (this.auctionSituation === 8) {
        return 'Enviar Proposta(s)';
      }
      return 'ERROR';
    },
  },
  sockets: {
    'pregao:lance-processado': function updateCompanyBidsAuction(bid) {
      if (this.bidder && this.userLoggedBidder.alias === bid.fornecedor) {
        if (bid.status === 'INVALIDO') {
          if (this.auctionSituation === 6 && !this.closedMode) {
            this.$alerta(`Lance Inválido no Item ${bid.identificador}`, 'is-danger', 3000);
          } else if (this.auctionSituation === 7) {
            this.$alerta(`Preferência Inválida no Item ${bid.identificador}`, 'is-danger', 3000);
          }
        }

        if (bid.tipo === 'FECHADO') {
          const index = this.closedBidsOnSubmission.findIndex(
            elemento => elemento.ofertaCompraItemId === bid.ofertaCompraItemId,
          );

          if (index > -1) {
            this.closedBidsOnSubmission.splice(index, 1);
          }
        }
        this.atualizarLancesItemEmpresa(bid);
      }
    },
  },
  methods: {
    ...mapActions([
      'invalidatePin',
      'adicionarLancesEmpresa',
      'atualizarLancesItemEmpresa',
      'excluirUltimoLanceEmpresa',
    ]),
    ...mapActions('auctionsDeclarations', ['createAuctionDeclaration', 'removeAuctionDeclaration']),
    ...mapActions('proposals', ['classificarItens', 'deleteProposal']),
    ...mapActions('auctionsBidders', ['fetchUserLoggedBidder']),
    async deleteLastBid(row) {
      const proceed = await this.$confirmacao({
        message:
          'Essa ação irá excluir o último lance do item no pregão eletrônico. Deseja prosseguir?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-danger',
        hasIcon: true,
      });
      if (!proceed) {
        return;
      }
      try {
        await excluirUltimoLance({
          ofertaCompraId: this.auction.id,
          itemId: row.id,
        });
        this.$alerta('O último lance foi excluido', 'is-success');
        this.fetchUserLoggedBidder(this.$route.params.id);
        this.excluirUltimoLanceEmpresa({ itemId: row.id, supplierId: this.userLoggedBidder.id });
      } catch (error) {
        this.$alerta('Erro ao excluir último lance', 'is-danger');
      }
    },
    addBid(bid) {
      const cloneBid = { ...bid };

      this.bidValues = cloneBid.valores;
      delete cloneBid.valores;

      if (!this.bids.length) {
        this.bids.push(cloneBid);
      } else {
        const index = this.bids.findIndex(
          lanceParaEnviar => lanceParaEnviar.ofertaCompraItemId === bid.ofertaCompraItemId,
        );

        if (index === -1) {
          this.bids.push(cloneBid);
        } else {
          Vue.set(this.bids, index, cloneBid);
        }
      }
    },
    allowBidDelete(row) {
      if (this.userLoggedBidder.excluiuLance) {
        return false;
      }
      const ultimoLance = this.auctionBids
        .filter(
          oferta =>
            oferta.ofertaCompraItemId === row.id &&
            oferta.fornecedor_id === this.userLoggedBidder.id,
        )
        .sort((a, b) => new Date(b.dataLance) - new Date(a.dataLance))[0];
      const dataUltimoLance = ultimoLance
        ? new Date(ultimoLance.dataLance).getTime()
        : this.fifteenSecondsAgo.value;
      return dataUltimoLance > this.fifteenSecondsAgo.value;
    },
    bidderExists(item) {
      return item.menor_lance.fornecedor ? item.menor_lance.fornecedor : 'Nenhum fornecedor';
    },
    canSendProposal(row) {
      if (!['CADASTRADA', 'LANCE-ENCERRADA', 'NEGOCIACAO'].includes(row.estado)) {
        this.$refs.tabela.closeDetailRow(row);
      }
      if (this.auctionSituation === 3 && row.item.exclusivo && !this.company.preference) {
        this.$refs.tabela.closeDetailRow(row);
        this.$alerta('Item exclusivo para empresa de porte com preferência', 'is-danger', 4000);
      }
      if (this.company.status !== 'HABILITADA' && !this.bidder) {
        this.$refs.tabela.closeDetailRow(row);
        this.$alerta('Entre em seu perfil e solicite validação da empresa', 'is-danger', 4000);
      }
    },
    cleanFields() {
      this.percentageWarning = false;
      this.$emitBus('limparCampos');
    },
    async createDeclaration(declarationType, declarationValue) {
      try {
        if (declarationValue) {
          await this.createAuctionDeclaration({
            auctionId: this.auction.id,
            companyId: this.company.id,
            type: declarationType,
            value: declarationValue,
          });
        } else {
          const type = declarationType === 'requirements' ? 'REQUISITOS' : 'PREFERENCIA-REGIONAL';
          const declaration = this.auctionDeclarations.find(
            element => element.declaration === type,
          );

          await this.removeAuctionDeclaration({
            auctionId: this.auction.id,
            companyId: this.company.id,
            declarationId: declaration.id,
          });
        }
      } catch (error) {
        if (error && error.response) {
          const { data } = error.response;
          if (data.error === 'ValidationError' && data.details.message === 'Fora do prazo') {
            this.$alerta(data.details.message, 'is-danger');
            return;
          }
        }

        this.$alerta(
          declarationValue ? 'Erro ao aceitar declaração' : 'Erro ao remover declaração',
          'is-danger',
        );
      }
    },
    async deleteAuctionProposal() {
      this.mode = 'delete';

      if (this.validPin) {
        const itemProposal = this.proposals.find(
          proposta => proposta.oferta_compra_item_id === this.data.itemId,
        );
        if (itemProposal) this.data.propostaId = itemProposal.id;

        this.disable.proposalDelete = true;
        try {
          await this.deleteProposal({
            auctionItemId: this.data.itemId,
            proposalId: this.data.propostaId,
          });
          this.$alerta('Proposta excluída', 'is-success');
          this.componentKey += 1;
        } catch (error) {
          const resposta = error.response.data;
          if (resposta.error.type === 'ForaDoPrazo') {
            this.$alerta('Fora do prazo', 'is-danger');
          } else {
            this.$alerta('Erro ao excluir proposta', 'is-danger');
          }
        }
        this.mode = '';
        this.validatingPIN = false;
        this.invalidatePin(false);
        this.disable.proposalDelete = false;
      }
    },
    displayPercentageWarning(booleano) {
      if (this.auctionSituation === 6) this.percentageWarning = booleano;
    },
    displaySendDocuments(item) {
      return (
        (this.auctionSituation === 9 && this.crier) ||
        (item.menor_lance.fornecedor === this.userLoggedBidder.alias &&
          item.estado === 'HABILITACAO')
      );
    },
    async editProposal(row) {
      const openItem = this.detailsList.some(itemLista => itemLista === row.id);
      if (!openItem) {
        await this.detailsList.push(row.id);
      }

      this.$emitBus('editar', row.id);
    },
    focusValueInput() {
      if ([6, 7, 8].indexOf(this.auctionSituation) > -1) {
        if (this.closedMode) {
          let reference;
          this.items.forEach((elemento, indice) => {
            const valor = `${this.biggestDiscount ? 'percentual' : 'valor'}-${indice}`;
            if (Object.prototype.hasOwnProperty.call(this.$refs, valor)) {
              if (this.$refs[valor].exibirCampoLanceFechado && !reference) reference = valor;
            }
          });
          if (reference) {
            setTimeout(() => {
              this.$refs[reference].focus();
            }, 100);
          }
        } else {
          setTimeout(() => {
            this.$refs[`${this.biggestDiscount ? 'percentual-0' : 'valor-0'}`].focus();
          }, 100);
        }
      }
    },
    informPIN() {
      this.$nextTick(() => {
        if (this.$refs.sendBidsButton !== undefined) this.$refs.sendBidsButton.focus();
      });

      const greaterThanZero = this.bids.some(lance => lance.percentual || lance.valor);
      const invalidBid = this.bids.some(lance => lance.invalido);
      const validBid = this.bids.some(lance => !lance.invalido);

      if (!this.bids.length || !greaterThanZero) {
        this.$alerta('Nenhum lance inserido', 'is-danger', 3000);
        this.focusValueInput();
        return;
      }

      if (invalidBid && !validBid) {
        this.$alerta('Existe valor inválido', 'is-danger', 3000);
        this.focusValueInput();
        return;
      }

      if (this.bids.length) {
        this.bids = this.bids
          .filter(lance => (this.biggestDiscount ? lance.percentual : lance.valor))
          .sort((a, b) => a.identificador - b.identificador);

        const bidAboveLimite = this.bids.find(lance => lance.valor > 999999999.9999);

        if (bidAboveLimite) {
          this.$alerta('Valor acima do limite: R$ 999.999.999,9999', 'is-danger', 4000);
          this.focusValueInput();
          return;
        }

        this.resetState();

        this.offerConfirmationModal = true;

        this.mountOfferConfirmation();
      }
    },
    mountOfferConfirmation() {
      this.confirmation.offer.display = {
        identifier: true,
        description: true,
        unity: true,
        brand: false,
        imported: false,
        percent: this.biggestDiscount,
        value: true,
      };

      this.confirmation.offer.message = {
        6: 'Confirme o(s) lance(s):',
        7: 'Confirme a(s) preferência(s)',
        8: 'Confirme a(s) oferta(s)',
      }[this.auctionSituation];

      this.confirmation.offer.items = this.bids.map(bid => ({
        identifier: bid.identificador,
        description: bid.descricao,
        unity: bid.unidadeNome,
        percent: this.biggestDiscount ? bid.percentual : null,
        value: bid.valor,
      }));

      this.requestPIN = false;
      this.displayConfirmation.offer = true;
      this.offerConfirmationModal = true;
    },
    mountPreferenceConfirmation() {
      this.confirmation.preference.display = {
        identifier: true,
        description: true,
        unity: true,
        brand: false,
        imported: false,
        percent: false,
        value: false,
      };

      this.confirmation.preference.message =
        'Você está abdicando do direito de preferência para o Item:';
      this.confirmation.preference.iconType = 'is-danger';
      this.confirmation.preference.confirmType = 'is-danger';

      this.confirmation.preference.items = [];
      this.confirmation.preference.items.push({
        identifier: this.abdicatePreferenceData.itemIdentifier,
        description: this.abdicatePreferenceData.itemDescription,
        unity: this.abdicatePreferenceData.itemUnity,
        percent: null,
      });

      this.preferenceAbdicatePin = false;
      this.displayConfirmation.preference = true;
      this.preferenceConfirmationModal = true;
    },
    openDetails(row) {
      if ((this.company.status !== 'HABILITADA' && !this.bidder) || row.estado === 'REVOGADO') {
        return;
      }
      if (this.auctionSituation === 3 && row.item.exclusivo && !this.company.preference) {
        this.$alerta('Item exclusivo para empresas de porte com preferência', 'is-danger', 4000);
        return;
      }
      if (this.detailsList.length) {
        const openItem = this.detailsList.findIndex(itemLista => itemLista === row.id);
        if (openItem > -1) {
          this.detailsList.splice(openItem, 1);
          return;
        }
      }
      this.detailsList.push(row.id);
    },
    async preferenceAbdicate() {
      if (!this.validPin) {
        this.displayConfirmation.preference = false;
        this.requestPIN = true;
        this.reasonPIN = this.getReasonPIN;
        return;
      }

      this.loading.confirm = true;
      this.disable.abdicate = true;
      try {
        await this.abdicarPreferencia(this.abdicatePreferenceData.itemId);
        this.$alerta('Direito de preferência abdicado', 'is-success', 3000);
      } catch (error) {
        this.$alerta('Não foi possível abdicar do direito de preferência', 'is-danger', 3000);
      } finally {
        this.loading.confirm = false;
        this.disable.abdicate = false;
      }
    },
    proposalIcon(row) {
      if (this.auctionSituation !== 3 || row.estado === 'REVOGADO') return false;
      return this.proposals.find(proposal => proposal.oferta_compra_item_id === row.id);
    },
    async requestProposalDelete(row) {
      this.data.itemId = row.id;
      const proceed = await this.$confirmacao({
        message:
          'Essa ação irá excluir a proposta do item no pregão eletrônico. Deseja prosseguir?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-danger',
        hasIcon: true,
      });
      if (!proceed) {
        return;
      }

      this.validatingPIN = true;
      this.reasonPIN = 'PREGAO-EXCLUIR-PROPOSTA';
    },
    resetLoadings() {
      this.loading.general = false;
    },
    resetLoadingClassificacao() {
      this.loading.classificarItens = false;
    },
    resetState() {
      this.$emitBus('resetarEstado');
    },
    async sendBids() {
      if (!this.validPin) {
        this.displayConfirmation.offer = false;
        this.requestPIN = true;
        this.reasonPIN = this.getReasonPIN;
        return;
      }

      const mappedBids = this.bids.map(bid => {
        const cloneBid = { ...bid };
        if (this.biggestDiscount) {
          delete cloneBid.valor;
        } else {
          delete cloneBid.percentual;
        }

        return cloneBid;
      });

      this.loading.general = true;
      if (this.auctionSituation === 6) {
        if (this.closedMode) {
          this.closedBidsOnSubmission = [...mappedBids];
        }
        await enviarLance(this.auction.id, mappedBids)
          .then(data => {
            if (data.data.length === 1) {
              this.$alerta('Lance Enviado', 'is-info', 1000);
            } else if (data.data.length > 1) {
              this.$alerta('Lances Enviados', 'is-info', 1000);
            }
            this.adicionarLancesEmpresa(data.data);
            if (this.openItem) this.focusValueInput();
          })
          .catch(error => {
            if (error.response && Array.isArray(error.response.data.error)) {
              const outOfTime = error.response.data.error.some(
                element => element.status === 'ForaDoPrazo',
              );

              if (outOfTime) {
                this.$alerta('Tempo finalizado', 'is-danger');
                return;
              }
            }
            this.$alerta('Erro ao enviar lance', 'is-danger');
          });
      } else if (this.auctionSituation === 7) {
        await enviarPreferencia(this.auction.id, mappedBids)
          .then(data => {
            if (data.data.length === 1) {
              this.$alerta('Preferência Enviada', 'is-info', 1000);
            } else if (data.data.length > 1) {
              this.$alerta('Preferências Enviadas', 'is-info', 1000);
            }
            this.adicionarLancesEmpresa(data.data);
            if (this.openItem) this.focusValueInput();
          })
          .catch(error => {
            if (error.response && Array.isArray(error.response.data.error)) {
              const outOfTime = error.response.data.error.some(
                element => element.status === 'ForaDoPrazo',
              );

              if (outOfTime) {
                this.$alerta('Tempo finalizado', 'is-danger');
                return;
              }
            }
            this.$alerta('Erro ao enviar preferência', 'is-danger');
          });
      } else if (this.auctionSituation === 8) {
        await enviarLanceNegociacao(this.auction.id, mappedBids)
          .then(data => {
            const proposta = data.data;
            if (proposta.length === 1) {
              this.$alerta('Proposta Enviada', 'is-info');
            } else if (proposta.length > 1) {
              this.$alerta('Propostas Enviadas', 'is-info');
            }
            this.adicionarLancesEmpresa(proposta);
            if (this.openItem) this.focusValueInput();
          })
          .catch(() => {
            this.$alerta('Erro ao enviar proposta', 'is-danger');
          });
      }

      this.bids = [];
      this.cleanFields();
      this.loading.general = false;
    },
    selectProposalKey(itemId) {
      if (['delete', 'import'].indexOf(this.mode) > -1) return this.componentKey;
      return itemId;
    },
    verifyPIN(itemId, itemIdentifier, itemDescription, itemUnity) {
      this.abdicatePreferenceData = { itemId, itemIdentifier, itemDescription, itemUnity };
      if (!this.validPin) {
        this.preferenceAbdicatePin = true;
        this.reasonPIN = 'PREGAO-ABDICAR-PREFERENCIA';
        this.preferenceConfirmationModal = true;
      } else {
        this.mountPreferenceConfirmation();
      }
    },
    viewAllItems() {
      this.$emit('view-all-items');
    },
    verifySituation(auctionSituation) {
      return this.auction.situacao.id === auctionSituation;
    },
    classificarPropostasItens() {
      this.loading.classificarItens = true;
      this.justificar = true;
    },
    async classificarPropostas({ justification, state }) {
      // eslint-disable-next-line camelcase
      const itens = this.selectedItems.map(item => item.id);
      const classificacao = {
        justificativa: justification || 'Classificada.',
        estado: state === 'success' ? 'CLASSIFICADA' : 'DESCLASSIFICADA',
      };
      await this.classificarItens({ itens, classificacao });
      this.$alerta(
        `Propostas ${state === 'success' ? 'classificadas' : 'desclassificadas'} com sucesso`,
        'is-success',
      );
      this.loading.classificarItens = false;
      this.selectedItems = [];
      this.$refs.justificationModal.close();
    },
    checkableItems(item) {
      return item.estado === 'CADASTRADA' && item.propostasAClassificar;
    },
  },
  mounted() {
    this.auctionType =
      this.auction.tipo === 'PREGAO' ? 'Pregão Eletrônico' : 'Concorrência Eletrônica';
    this.$nextTick(() => {
      if (this.bidder) {
        this.focusValueInput();
        this.verifyOverflowed();
      }
    });
    setInterval(() => {
      this.fifteenSecondsAgo.value = new Date().getTime() - 15000;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.all-items-button-container {
  width: 25%;
}

.check-regional-preference {
  margin-top: 1rem;
  margin-left: unset !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.footer-container {
  display: flex;
  margin-top: 0.5rem;
  width: 100%;
}

.options-button {
  font-size: 0.9rem;
}

.terms {
  padding: 15px;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  max-width: 1150px;
}

.tm-1 {
  margin-top: 1rem;
}

.send-button-container {
  width: 25%;
}

.value-warning-container {
  width: 50%;
}

@media (max-width: 768px) {
  .value-warning {
    font-size: 0.7rem;
  }
}
</style>

<style lang="scss">
.custom-eye-icon .mdi-eye-outline::before {
  font-size: 22px;
}

.auction-items-table .table-wrapper .table {
  max-width: 99.8%;
}

@media (min-width: 769px) {
  .elipsis-size {
    max-width: 1px;
    width: 100%;
  }
}
</style>

<style lang="scss">
#my-items-options-field .field-body .field {
  justify-content: center !important;
}
</style>

<style src="@/styles/items.scss" lang="scss" scoped />
