<template>
  <div>
    <section>
      <h1 class="title is-4">Valores Negociados</h1>
      <div class="box">
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Pregão (Total)</p>
              <p class="title is-4">{{ negotiationValues.auction.total | formatarValor }}</p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Pregão (Média)</p>
              <p class="title is-4">{{ negotiationValues.auction.average | formatarValor }}</p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Concorrência (Total)</p>
              <p class="title is-4">{{ negotiationValues.concurrence.average | formatarValor }}</p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Concorrência (Média)</p>
              <p class="title is-4">{{ negotiationValues.concurrence.average | formatarValor }}</p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Dispensa (Total)</p>
              <p class="title is-4">{{ negotiationValues.dispensation.total | formatarValor }}</p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Dispensa (Média)</p>
              <p class="title is-4">{{ negotiationValues.dispensation.average | formatarValor }}</p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <router-link :to="{ name: 'ItemPesquisa' }">
              <button class="button is-primary negotiations-back-button" type="button">
                Voltar
              </button></router-link
            >
          </div>
        </nav>
      </div>
    </section>

    <section class="negotiations">
      <h1 class="title is-4">Pregões Eletrônicos</h1>
      <div class="panel">
        <div class="panel-block is-justify-content-center">
          <section v-if="!auctionNegotiations.length" class="section">
            <div class="content has-text-grey">
              <p>Nenhum pregão.</p>
            </div>
          </section>

          <b-table
            class="table is-fullwidth negotiations-table"
            v-if="auctionNegotiations.length"
            :data="auctionNegotiations"
          >
            <b-table-column centered label="Oferta de Compra" v-slot="props">
              <router-link :to="{ name: 'Pregao', params: { id: props.row.id } }">
                {{ props.row.numeroOC }}
              </router-link>
            </b-table-column>

            <b-table-column centered label="Situação" v-slot="props">
              {{ props.row.situationName }}
            </b-table-column>

            <b-table-column centered label="Previsão de Abertura" v-slot="props">
              {{ props.row.dataAbertura | formatarData }}
            </b-table-column>

            <b-table-column centered label="Comprador" v-slot="props">
              {{ props.row.compradorNome }}
            </b-table-column>

            <template v-slot:footer>
              <AppPagination
                :data-filters="{ page: auctionFilters.page, perPage: auctionFilters.perPage }"
                :number-lines="numberOfLines"
                :total="auctionNegotiationsCount"
                @change-page="
                  page => {
                    auctionFilters.page = page;
                    fetchAuctionNegotiations({
                      type: 'PREGAO',
                      itemId: $route.params.id,
                      auctionFilters,
                    });
                  }
                "
                @change-per-page="
                  perPage => {
                    auctionFilters.perPage = perPage;
                    fetchAuctionNegotiations({
                      type: 'PREGAO',
                      itemId: $route.params.id,
                      auctionFilters,
                    });
                  }
                "
              />
            </template>
          </b-table>
        </div>
      </div>

      <h1 class="title is-4">Concorrências Eletrônicas</h1>
      <div class="panel">
        <div class="panel-block is-justify-content-center">
          <section v-if="!concurrenceNegotiations.length" class="section">
            <div class="content has-text-grey">
              <p>Nenhuma concorrência.</p>
            </div>
          </section>

          <b-table
            class="table is-fullwidth negotiations-table"
            v-if="concurrenceNegotiations.length"
            :data="concurrenceNegotiations"
          >
            <b-table-column centered label="Oferta de Compra" v-slot="props">
              <router-link :to="{ name: 'Pregao', params: { id: props.row.id } }">
                {{ props.row.numeroOC }}
              </router-link>
            </b-table-column>

            <b-table-column centered label="Situação" v-slot="props">
              {{ props.row.situationName }}
            </b-table-column>

            <b-table-column centered label="Previsão de Abertura" v-slot="props">
              {{ props.row.dataAbertura | formatarData }}
            </b-table-column>

            <b-table-column centered label="Comprador" v-slot="props">
              {{ props.row.compradorNome }}
            </b-table-column>

            <template v-slot:footer>
              <AppPagination
                :data-filters="{
                  page: concurrenceFilters.page,
                  perPage: concurrenceFilters.perPage,
                }"
                :number-lines="numberOfLines"
                :total="concurrenceNegotiationsCount"
                @change-page="
                  page => {
                    concurrenceFilters.page = page;
                    fetchAuctionNegotiations({
                      type: 'CONCORRENCIA',
                      itemId: $route.params.id,
                      concurrenceFilters,
                    });
                  }
                "
                @change-per-page="
                  perPage => {
                    concurrenceFilters.perPage = perPage;
                    fetchAuctionNegotiations({
                      type: 'CONCORRENCIA',
                      itemId: $route.params.id,
                      concurrenceFilters,
                    });
                  }
                "
              />
            </template>
          </b-table>
        </div>
      </div>

      <h1 class="title is-4">Dispensas</h1>
      <div class="panel">
        <div class="panel-block is-justify-content-center">
          <section v-if="!dispensationNegotiations.length" class="section">
            <div class="content has-text-grey">
              <p>Nenhuma dispensa.</p>
            </div>
          </section>

          <b-table
            class="table is-fullwidth negotiations-table"
            v-if="dispensationNegotiations.length"
            :data="dispensationNegotiations"
          >
            <b-table-column centered label="Dispensa" v-slot="props">
              <router-link :to="{ name: 'Dispensa', params: { id: props.row.id } }">
                {{ props.row.numeroDispensa }}
              </router-link>
            </b-table-column>

            <b-table-column centered label="Situação" v-slot="props">
              {{ props.row.situationName }}
            </b-table-column>

            <b-table-column centered label="Previsão de Abertura" v-slot="props">
              {{ props.row.dataAbertura | formatarData }}
            </b-table-column>

            <b-table-column centered label="Comprador" v-slot="props">
              {{ props.row.compradorNome }}
            </b-table-column>

            <template v-slot:footer>
              <AppPagination
                :data-filters="{
                  page: dispensationFilters.page,
                  perPage: dispensationFilters.perPage,
                }"
                :number-lines="numberOfLines"
                :total="dispensationNegotiationsCount"
                @change-page="
                  page => {
                    dispensationFilters.page = page;
                    fetchDispensationNegotiations({
                      itemId: $route.params.id,
                      dispensationFilters,
                    });
                  }
                "
                @change-per-page="
                  perPage => {
                    dispensationFilters.perPage = perPage;
                    fetchDispensationNegotiations({
                      itemId: $route.params.id,
                      dispensationFilters,
                    });
                  }
                "
              />
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { formatarValor, formatarData } from '@/utils/format';

import AppPagination from '@/components/AppPagination';

export default {
  name: 'ItemNegociacoes',
  components: {
    AppPagination,
  },
  data() {
    const auctionFilters = {
      page: 1,
      perPage: 20,
    };
    const concurrenceFilters = {
      page: 1,
      perPage: 20,
    };
    const dispensationFilters = {
      page: 1,
      perPage: 20,
    };
    const opcoesModalidades = [
      { id: 1, nome: 'Pregão' },
      { id: 3, nome: 'Dispensa' },
    ];
    const opcoesSituacoes = [
      { id: 1, nome: 'Abertas' },
      { id: 2, nome: 'Em Andamento' },
      { id: 3, nome: 'Encerradas' },
    ];

    return {
      auctionFilters,
      concurrenceFilters,
      dispensationFilters,
      numberOfLines: 10,
      opcoesModalidades,
      opcoesSituacoes,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('itens', [
      'auctionNegotiations',
      'auctionNegotiationsCount',
      'concurrenceNegotiations',
      'concurrenceNegotiationsCount',
      'dispensationNegotiations',
      'dispensationNegotiationsCount',
      'negotiationValues',
    ]),
    ...mapGetters({
      ofertasCompra: 'obterOfertasCompra',
      prazos: 'obterOfertaCompraPrazos',
    }),
    etapaAdesao() {
      return this.prazos.some(prazo => prazo.prazo_id === 13);
    },
    isTouch() {
      return this.windowWidth < 1024;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'ItemPesquisa') {
      this.limparPesquisa();
    }

    next();
  },
  filters: {
    formatarValor,
    formatarData,
  },
  methods: {
    ...mapActions('itens', [
      'fetchNegotiationValues',
      'fetchAuctionNegotiations',
      'fetchDispensationNegotiations',
      'limparPesquisa',
    ]),
    nomeSituacao(row) {
      const situacao = row.situacao.nome;
      if (situacao === 'Lances' && row.modo_disputa === 'FECHADO' && this.modoFechado(row)) {
        return 'Lances - Fechado';
      }
      if (situacao === 'Lances' && row.modo_disputa === 'FECHADO') return 'Lances - Aberto';
      if (situacao === 'Habilitação' && row.prazo_adesao) {
        return 'Habilitação da Adesão';
      }
      if (situacao === 'Fracassada') return 'Fracassado';
      if (situacao === 'Deserta') return 'Deserto';
      if (situacao === 'Revogada') return 'Revogado';
      if (situacao === 'Encerrada com Vencedor') return 'Encerrado com Vencedor';
      if (situacao === 'Encerrada com Recurso') return 'Encerrado com Recurso';
      if (situacao === 'Suspensa') return 'Suspenso';
      return situacao;
    },
    modoFechado(row) {
      return this.ofertasCompra.some(
        ofertaCompra =>
          ofertaCompra.id === row.id &&
          ofertaCompra.itens.some(
            item => ['LANCE-FECHADO', 'PRIMEIRA-CHAMADA', 'NOVA-CHAMADA'].indexOf(item.estado) > -1,
          ),
      );
    },
  },
  mounted() {
    this.fetchNegotiationValues(this.$route.params.id);
    this.fetchAuctionNegotiations({
      type: 'PREGAO',
      itemId: this.$route.params.id,
      filters: this.auctionFilters,
    });
    this.fetchAuctionNegotiations({
      type: 'CONCORRENCIA',
      itemId: this.$route.params.id,
      filters: this.concurrenceFilters,
    });
    this.fetchDispensationNegotiations({
      itemId: this.$route.params.id,
      filters: this.dispensationFilters,
    });
  },
};
</script>

<style lang="scss" scoped>
.negotiations {
  margin-top: 4rem;
}

@media (max-width: 1500px) {
  .negotiations-table {
    font-size: 0.92rem;
  }
}

@media (max-width: 1023px) {
  .negotiations-back-button {
    margin-top: 1rem;
  }
}
</style>
