<template>
  <div>
    <form>
      <b-field grouped class="column-direction-touch">
        <AppPorcentagem
          ref="inputPercentual"
          v-if="maiorDesconto"
          permitir-percentual-zero
          :calcular-valor="maiorDesconto"
          :carregar-percentual="carregarDados"
          :desabilitar-campos="desabilitarCamposProposta"
          :label="'Percentual'"
          :placeholder="'Percentual'"
          :item="item"
          :percentual-valido="percentualValido"
          :valores="valores"
          :valor-percentual="dados.percentual"
          :width="'110'"
          @valor="atualizarValores"
        />

        <AppValor
          ref="inputValor"
          v-if="!maiorDesconto || (maiorDesconto && existeReferencial)"
          :calcular-percentual="maiorDesconto"
          :carregar-valor="carregarDados"
          :desabilitar-campos="desabilitarCamposProposta"
          :label="'Valor'"
          :exibir-input="!maiorDesconto"
          :item="item"
          :placeholder="!maiorDesconto ? 'Valor' : ''"
          :valor-valido="valorValido"
          :valores="valores"
          :valor-monetario="dados.valor"
          :width="'160'"
          @valor="atualizarValores"
        />

        <b-field
          expanded
          label="Marca/Modelo"
          :type="campoInvalido('marca') ? 'is-danger' : ''"
          :message="campoInvalido('marca') ? 'Insira uma marca' : ''"
        >
          <b-input
            expanded
            custom-class="uppercase"
            placeholder="Marca/Modelo"
            :disabled="desabilitarCamposProposta"
            v-model="$v.dados.marca.$model"
            @blur="evento => converterMaiusculo('marca', evento.target.value)"
          ></b-input>
        </b-field>

        <b-field
          :expanded="tablet"
          label="Fabricado"
          :type="campoInvalido('fabricado') ? 'is-danger' : ''"
          :message="campoInvalido('fabricado') ? 'Selecione uma opção' : ''"
        >
          <b-field :expanded="tablet">
            <b-radio-button
              :expanded="tablet"
              v-model="dados.fabricado"
              native-value="BRASIL"
              :disabled="desabilitarCamposProposta"
              >Brasil</b-radio-button
            >
            <b-radio-button
              :expanded="tablet"
              v-model="dados.fabricado"
              native-value="IMPORTADO"
              :disabled="desabilitarCamposProposta"
              >Importado</b-radio-button
            >
          </b-field>
        </b-field>

        <b-field>
          <button
            class="button is-primary is-fullwidth send-proposal-button"
            style="height: 100%"
            @click.stop.prevent="informarPIN(item.id)"
            :disabled="desabilitarCamposProposta"
          >
            {{ textoBotao }}
          </button>
        </b-field>
      </b-field>
    </form>

    <b-modal :active.sync="validandoPIN" :onCancel="resetarEstado" has-modal-card>
      <pin-form v-if="validandoPIN" :reason="reasonPIN" @validar="enviarProposta" />
    </b-modal>
  </div>
</template>

<script>
import Big from 'big.js';
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import AppValor from '@/components/AppValor';
import AppPorcentagem from '@/components/AppPorcentagem';
import campo from '@/mixins/formulario';
import PinForm from '@/views/User/Pin';

export default {
  name: 'SendProposal',
  props: {
    item: Object,
  },
  components: {
    PinForm,
    AppValor,
    AppPorcentagem,
  },
  mixins: [campo],
  data() {
    const dados = {
      ofertaCompraItemId: 0,
      valor: 0,
      percentual: 0,
      marca: '',
      fabricado: '',
    };

    return {
      dados,
      reasonPIN: '',
      validandoPIN: false,
      desabilitarCamposProposta: false,
      modo: '',
      carregarDados: false,
      valores: {},
    };
  },
  validations: {
    dados: {
      marca: { required },
      fabricado: { required },
    },
  },
  computed: {
    ...mapGetters('app', { tablet: 'tablet' }),
    ...mapState('companies', ['company']),
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
      pinValido: 'getValidPin',
    }),
    ...mapGetters('proposals', { proposals: 'obterPropostasUsuarioLogado' }),
    existeReferencial() {
      return this.item.referencia || this.item.total;
    },
    maiorDesconto() {
      return this.tipoNegociacao === 3;
    },
    valorValido() {
      if (Number.isNaN(this.dados.valor)) return false;
      const valor = new Big(this.dados.valor);
      if (this.maiorDesconto && this.divulgarReferencial) {
        const valorReferencia = new Big(this.item.total);

        return valor.lte(valorReferencia);
      }
      return valor.gt(0) && valor.lt(999999999.9999);
    },
    percentualValido() {
      const percentual = new Big(this.dados.percentual);

      return percentual.gte(0) && percentual.lt(100);
    },
    tipoNegociacao() {
      return this.ofertaCompra.tipo_negociacao.id;
    },
    textoBotao() {
      if (this.modo === 'editar') return 'Atualizar Proposta';
      return 'Enviar Proposta';
    },
  },
  methods: {
    ...mapActions('proposals', ['createProposal', 'updateProposal']),
    ...mapActions(['invalidatePin']),
    atualizarValores(dados) {
      this.dados.valor = dados.valor;
      this.dados.percentual = dados.percentual;
      this.valores = dados;
    },
    async habilitarDesabilitarCampos(itemId) {
      const propostaFiltro =
        (await this.proposals.findIndex(proposta => proposta.oferta_compra_item_id === itemId)) >=
        0;

      if (propostaFiltro && this.$vnode.key === itemId && this.modo === '') {
        this.desabilitarCamposProposta = false;
        this.modo = 'editar';
      } else if (propostaFiltro && this.$vnode.key === itemId && this.modo === 'editar') {
        this.desabilitarCamposProposta = true;
        this.modo = '';
      }
    },
    async envioPropostaItemValido() {
      const propostaFiltro =
        (await this.proposals.findIndex(
          proposta => proposta.oferta_compra_item_id === this.item.id,
        )) >= 0;

      if (propostaFiltro || this.item.estado === 'REVOGADO') this.desabilitarCamposProposta = true;
      return false;
    },
    definirProposta() {
      const proposta = this.proposals.find(prop => prop.oferta_compra_item_id === this.item.id);
      if (proposta) {
        this.dados.valor = Number(proposta.valor);
        this.dados.percentual = Number(proposta.percentual);
        this.dados.ofertaCompraItemId = proposta.oferta_compra_item_id;
        this.dados.marca = proposta.marca;
        this.dados.fabricado = proposta.fabricado;
        this.carregarDados = true;
      } else if (this.maiorDesconto) {
        this.$refs.inputPercentual.focus();
      } else {
        this.$refs.inputValor.focus();
      }
    },
    async informarPIN(ofertaCompraItemId) {
      this.dados.ofertaCompraItemId = ofertaCompraItemId;

      if (
        [1, 2].indexOf(this.tipoNegociacao) > -1 &&
        (this.$v.$invalid || this.$refs.inputValor.$v.$invalid)
      ) {
        this.$v.$touch();
        this.$refs.inputValor.$v.$touch();
      } else if (
        this.maiorDesconto &&
        (this.$v.$invalid || this.$refs.inputPercentual.$v.$invalid)
      ) {
        this.$v.$touch();
        this.$refs.inputPercentual.$v.$touch();
      } else if (!this.pinValido) {
        this.validandoPIN = true;
        this.reasonPIN =
          this.modo === 'editar' ? 'PREGAO-ATUALIZAR-PROPOSTA' : 'PREGAO-CRIAR-PROPOSTA';
      } else {
        await this.enviarProposta(true);
      }
    },
    async enviarProposta(pinValido) {
      if (pinValido) {
        this.dados.empresaId = this.company.id;
        if (this.maiorDesconto) {
          delete this.dados.valor;
        } else {
          delete this.dados.percentual;
        }

        if (this.modo === '') {
          try {
            await this.createProposal(this.dados);
            this.$alerta('Proposta enviada', 'is-success');
          } catch (error) {
            const resposta = error.response.data;
            if (resposta.error.type === 'ForaDoPrazo') {
              this.$alerta('Fora do prazo', 'is-danger');
            } else {
              this.$alerta('Erro ao enviar proposta', 'is-danger');
            }
            this.limparDados();
          }
        } else if (this.modo === 'editar') {
          const propostaItem = this.proposals.find(
            proposta => proposta.oferta_compra_item_id === this.dados.ofertaCompraItemId,
          );
          if (propostaItem) this.dados.propostaId = propostaItem.id;

          try {
            const { propostaId, ofertaCompraItemId: itemId } = this.dados;
            const proposta = this.dados;
            await this.updateProposal({ propostaId, itemId, proposta });
            this.$alerta('Proposta atualizada', 'is-success');
          } catch (error) {
            const resposta = error.response.data;
            if (resposta.error.type === 'ForaDoPrazo') {
              this.$alerta('Fora do prazo', 'is-danger');
            } else {
              this.$alerta('Erro ao atualizar proposta', 'is-danger');
            }
            this.valoresMascara.valor = `R$ ${propostaItem.valor}`;
            this.dados.valor = propostaItem.valor;
            this.dados.marca = propostaItem.marca;
            this.dados.fabricado = propostaItem.fabricado;
          }
        }
        this.desabilitarCamposProposta = true;

        if (this.maiorDesconto) {
          this.$refs.inputPercentual.formatarValor();
        } else {
          this.$refs.inputValor.formatarValor();
        }
        this.resetarEstado();
      }
    },
    limparDados() {
      this.dados.valor = 0;
      this.dados.marca = '';
      this.dados.fabricado = '';
    },
    resetarEstado() {
      if (this.modo === 'editar') this.desabilitarCamposProposta = true;
      this.modo = '';
    },
  },
  mounted() {
    this.definirProposta();
    this.envioPropostaItemValido();
    this.$onBus('editar', this.habilitarDesabilitarCampos);
    this.$onBus('cancelar-editar', this.habilitarDesabilitarCampos);
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1023px) {
  .send-proposal-button {
    margin-top: 0.5rem;
  }
}
</style>
