import { api } from './http';

export const pesquisa = filters => {
  let params = '?';

  if (filters) {
    if (filters.description) params += `description=${filters.description}&`;
    if (filters.type) params += `type=${filters.type}&`;
    if (filters.page) params += `page=${filters.page}&`;
    if (filters.perPage) params += `per_page=${filters.perPage}&`;
  }

  return api.get(`/items${params}`);
};

export const fetchAuctionNegotiations = (itemId, type, filters) => {
  let params = `?type=${type}&`;
  if (filters) {
    if (filters.page) params += `page=${filters.page}&`;
    if (filters.perPage) params += `per_page=${filters.perPage}`;
  }

  return api.get(`/items/${itemId}/auction-negotiations${params}`);
};
export const fetchDispensationNegotiations = (itemId, filters) => {
  let params = '?';
  if (filters) {
    if (filters.page) params += `page=${filters.page}&`;
    if (filters.perPage) params += `per_page=${filters.perPage}`;
  }

  return api.get(`/items/${itemId}/dispensation-negotiations${params}`);
};
export const fetchNegotiationValues = itemId => api.get(`/items/${itemId}/negotiation-values`);

export const obter = code => api.get(`/items?name=${code}`);

export const abdicarDireito = (ofertaCompraId, itemId) =>
  api.post(`/ofertasCompra/${ofertaCompraId}/itens/${itemId}/abdicar-direito-preferencia`);

export const adesaoMenorPreco = (ofertaCompraId, itemId, aderido) =>
  api.post(`/ofertasCompra/${ofertaCompraId}/itens/${itemId}/adesao-menor-preco`, aderido);

export const buscarItemAdesaoEmpresas = (ofertaCompraId, itemId) =>
  api.get(`/ofertasCompra/${ofertaCompraId}/itens/${itemId}/adesao-empresas`);

export const fetchItemUnits = () => api.get('/items/units');
